<template>
  <div>
    <div class="card animated fadeIn">
      <div class="card-header" :class="this.gdeSync.status ? 'bgm-green' : 'bgm-gray'">
        <h2>{{titulo}}</h2>
        <div>
          <!-- <button v-if="isEditable" @click.prevent="editItem()" class="btn bgm-blue btn-float waves-effect"><i class="zmdi zmdi-edit"></i></button> -->
        </div>
      </div>
      <div v-if="isLoading" style="padding-bottom: 1rem;" class="loading card-body">
        <unc-loader></unc-loader>
      </div>
      <div v-else>
        <div v-show="gdeSync.status" class="card-body card-padding">
          <div class="my-view-block">
            <ul>
              <!-- ==== NUEVOS CAMPOS -->
              <li>
                <label>Habilitó GDE</label>
                <a 
                    style="font-size: 20px;margin-left: 5px;"
                    data-toggle="popoverHabilito" 
                    data-placement="right"
                    tabindex="0" 
                    data-original-title="Aclaración">
                    <i class="zmdi zmdi-pin-help"></i>
                </a> 
                <br>{{gdeData.sn?'Sí':'No'}}
              </li>
              <li>
                <label>Contraseña sincronizada en GDE</label>
                <a 
                    style="font-size: 20px;margin-left: 5px;"
                    data-toggle="Sync" 
                    data-placement="right"
                    tabindex="0" 
                    data-original-title="Aclaración">
                    <i class="zmdi zmdi-pin-help"></i>
                </a> 
                <br>
                <span>{{gdeDataSync ? (gdeDataSync.synced? 'Contraseña sincronizada': 'Contraseña no sincronizada'):'No se pudo obtener ésta información'}}</span>
              </li>
              <li>
                <label>Ingresó a GDE</label>
                <br>{{gdeDataByCuil.fechaAlta?'Sí':gdeSync.msg}}
              </li>
              <li>
                <label>Fecha de habilitación en GDE</label>
                <br>{{ gdeDataByCuil.fechaAlta ? gdeFechaAlta : gdeSync.msg }}
              </li>
            </ul>
          </div>
        </div>
        <div v-show="!gdeSync.status" class="card-body card-padding">
          <div class="my-view-block">
            {{gdeSync.msg}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTPFront, swal, moment } from '../../share.js';

export default {
  data() {
    return {
      titulo: "Estado en GDE",
      item: "",
      isEditable: false,
      isLoading: true,
      validator: "",
      personSearch : "",
      gdeData:"",
      gdeDataByCuil:"",
      UNCuser:"",
      CUILuser:"",
      gdeDataSync: "",
      gdeSync: {
        status: false,
        msg:'No corresponde el acceso a GDE. Solo pueden acceder a GDE los usuarios con dominio @unc.edu.ar'
      }

    };
  },
  computed: {
    ...mapGetters(["person"]),
    gdeFechaAlta() {
        /* let timestamp = this.item._id.toString().substring(0,8); */
        let date = new Date(this.gdeDataByCuil.fechaAlta);
        let year = date.getFullYear();
        let month = date.getMonth()+1; 
        let day = date.getDate();
        return day + '/' + month + '/' + year
    }
  },
    created() {
    /* this.completeTermsPopOver(); */
      $(function () {
            $('[data-toggle="popoverHabilito"]').popover({
                content: '<p>La persona para verificar o habilitar GDE debe ingresar a MiUNC <a href="https://mi.unc.edu.ar">(https://mi.unc.edu.ar)</a>'+
                        '</p>',
                html : true
            })

            $('[data-toggle="Sync"]').popover({
                content: '<p>En caso que la contraseña no se encuentre sincronizada, solicitar al usuario que cambie la contraseña nuevamente desde MiUNC <a href="https://mi.unc.edu.ar">(https://mi.unc.edu.ar)</a>'+
                        '</p>',
                html : true
            })
      }) 

  },
   mounted() {
    this.personSearch = this.person;
    this.getData();
  },
  methods:{
      getData(){
        let self = this;
        self.UNCuser=self.personSearch.users.filter(
            user =>
              user.userName.includes("@unc") 
        );
        //Busco con el @unc (Si es un 200, sabemos que la persona se sincronizó con GDE y está en el LDAP)
        if(self.UNCuser.length){
          HTTPFront.get("/gdeinfobyunc/" + self.UNCuser[0].userName)
          .then(respuesta => {
            if (respuesta.status === 200 && respuesta.data) {
              self.gdeData = respuesta.data[0]
              self.gdeSync.status = true;

              let promises = [];

              promises.push(HTTPFront.get("/gdepasssync/" + self.UNCuser[0]._id)
              .then(respuesta => {
                if (respuesta.status === 200 && respuesta.data) {
                  self.gdeDataSync = respuesta.data
                }
              })          
              .catch(error => { 
                  self.gdeDataSync = null;
              }));

              //Busco por Cuil (Si es correcto, la persona ingresó al menos una vez)
              if(self.personSearch.identities){
                self.CUILuser = self.personSearch.identities.find(identity => { return identity.identityType === "ARG_CUIL" })
                
                if(self.CUILuser){
                  promises.push(HTTPFront.get("/gdeinfobycuil/" + self.CUILuser.identityNumber)
                  .then(respuesta => {
                    if (respuesta.status === 200 && respuesta.data) {
                      self.gdeDataByCuil = respuesta.data
                      self.gdeSync.status = true;
                    }
                  })          
                  .catch(error => { 
                    let status = error.response.status;
                    switch (status){
                      case 403:
                        self.gdeSync.msg = 'No tiene permisos para acceder a este servicio.'
                        break;
                      case 500:
                        self.gdeSync.msg = 'No se pudo acceder al servicio de información de GDE.'
                        break;
                        default:
                          self.gdeSync.msg = 'El Usuario UNC aún no habilitó la aplicación GDE desde MiUNC.'
                          break;
                      }
                    }))
                } else {
                  self.gdeSync.msg = 'La persona aún no ingresó por primera vez a GDE, no tiene asignado un documento de tipo CUIL.'
                }
              }

              return Promise.all(promises);
            }
          })
          .catch(error => {
            let status = error.response.status;
            switch (status){
              case 401:
                self.gdeSync.status = false;
                self.gdeSync.msg = 'No tiene permisos para acceder a este servicio.'
                break;
              case 403:
                self.gdeSync.status = false;
                self.gdeSync.msg = 'No tiene permisos para acceder a este servicio.'
                break;
              case 500:
                self.gdeSync.status = false;
                self.gdeSync.msg = 'No se pudo acceder al servicio de información de GDE.'
                break;
              default:
                self.gdeSync.status = false;
                self.gdeSync.msg = 'El Usuario UNC aún no habilitó el acceso a GDE desde MiUNC.'
                break;
            }
          })
          .finally(() => {
            self.isLoading = false;
          })
        }else {
          self.isLoading = false;
          self.gdeSync.status = false;
        } 
      }
  }
};
</script>
<style lang="scss" scoped>

    .my-listview-item {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }
    #tooltip {
     /* ... */
        display: none;
}

#tooltip[data-show] {
  display: block;
}

 #tooltip {
        background: #333;
        color: white;
        font-weight: bold;
        padding: 4px 8px;
        font-size: 13px;
        border-radius: 4px;
      }
</style>