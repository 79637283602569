<template>
  <div>
      <div class="card animated fadeIn">
      <div class="card-header" :class="this.googleSync.status ? 'bgm-green' : 'bgm-gray'">
        <h2>{{titulo}}</h2>
        <div>
          <!-- <button v-if="isEditable" @click.prevent="editItem()" class="btn bgm-blue btn-float waves-effect"><i class="zmdi zmdi-edit"></i></button> -->
        </div>
      </div>
      <div v-if="isLoading" style="padding-bottom: 1rem;" class="loading card-body">
        <unc-loader></unc-loader>
      </div>
      <div v-else>
        <div v-show="googleSync.status" class="card-body card-padding">
          <div class="my-view-block">
            <ul>
              <!-- ==== NUEVOS CAMPOS -->
              <li>
                <label>Usuario UNC</label>
                <br>{{ googleData.primaryEmail }}
              </li>
              <li>
                <label>Fecha y hora de habilitación en Google</label>
                <br>{{ googleData.creationTime ? googleCreationTime : googleSync.msg }}
              </li>
              <li v-if="googleData.recoveryEmail">
                <label>Correo electrónico de recuperación para notificaciones de Google</label>
                <br>{{ googleData.recoveryEmail }}
              </li>
              <li>
                <label>Licencia</label>
                <br>{{ licencesInfo ?? 'En este momento no pudimos obtener la licencia para este usuario'}}
              </li>
              <li>
                <label>Puede recibir correos electrónicos</label>
                <a 
                    style="font-size: 20px;margin-left: 5px;"
                    data-toggle="popoverTerms" 
                    data-placement="right"
                    tabindex="0" 
                    data-original-title="Aclaración">
                    <i class="zmdi zmdi-pin-help"></i>
                </a>                 
                <br>
                <span>{{googleData.agreedToTerms?'Sí':'No'}}</span>
              </li>
              <li>
                <label>Suspendido</label>
                <a 
                    style="font-size: 20px;margin-left: 5px;"
                    data-toggle="popoverSuspend" 
                    data-placement="right"
                    tabindex="0" 
                    data-original-title="Aclaración">
                    <i class="zmdi zmdi-pin-help"></i>
                </a> 
                <br>{{googleData.suspended?'Sí':'No'}}
              </li>
            </ul>
          </div>
        </div>
        <div v-show="!googleSync.status" class="card-body card-padding">
          <div class="my-view-block">
            {{googleSync.msg}}
          </div>
      </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import { mapGetters } from "vuex";
import { HTTPFront } from '../../share.js';

export default {
  data() {
    return {
      titulo: "Estado en Google",
      item: "",
      isEditable: false,
      isLoading: true,
      validator: "",
      googleData:"",
      licencesInfo: '',
      UNCuser:"",
      googleSync: {
        status: false,
        msg:'Obteniendo información del servicio de Google.'
      }
    };
  },
  computed: {
    ...mapGetters(["person"]),
    googleCreationTime() {        
        let date = new Date(this.googleData.creationTime);
        let year = date.getFullYear();
        let month = date.getMonth()+1; 
        let day = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
        /* return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes + ':' + seconds */
    }    
  },
  created() {
      $(function () {
            $('[data-toggle="popoverTerms"]').popover({
                content: '<p>Para poder recibir correos electrónicos, el usuario debe acceder al Correo UNC <a href="http://wmail.unc.edu.ar">(http://wmail.unc.edu.ar)</a> y aceptar los términos y condiciones.'+
                        '</p>',
                html : true
            })

            $('[data-toggle="popoverSuspend"]').popover({
                content: '<p>La persona que se encuentre suspendida en Google, no podrá acceder al Correo UNC <a href="http://wmail.unc.edu.ar">(http://wmail.unc.edu.ar)</a>. En caso de que no deba encontrarse suspendida, el administrador debe comunicarse a usuarios@informatica.unc.edu.ar informando la situación.'+
                        '</p>',
                html : true
            })
      }) 

  },
   async mounted() {
    this.personSearch = this.person;
    await this.getData();
    await this.getLicenceInfo();
    this.isLoading = false;
  },
  methods: {
    async getData () {         
      this.UNCuser=this.personSearch.users.filter(
          user =>
            user.userName.includes("@unc") || user.userName.includes("@mi.unc")
      );
    
      if(this.UNCuser.length) {
        try {
          const response = await HTTPFront.get("/googleinfo/" + this.UNCuser[0].userName);
          this.googleData = response.data
          this.googleSync.status = true;

        } catch (error) {
          let status = error.response.status;
          switch (status){
            case 403:
              this.googleSync.status = false;
              this.googleSync.msg = 'No se pudo acceder al servicio de información de Google.'
            break;
            case 500:
              this.googleSync.status = false;
              this.googleSync.msg = 'No se pudo acceder al servicio de información de Google.'
            break;            
            default:
              this.googleSync.status = false;
              this.googleSync.msg = 'El Usuario UNC aún no habilitó las aplicaciones de Google desde MiUNC.'
            break;
          }
        }
      } else {
        this.googleSync.status = false;
        this.googleSync.msg = 'La persona aún no ha creado su Usuario UNC.'
      }    
    },
    async getLicenceInfo () {
      try {
        const response = await HTTPFront.get(`google-licence-info/${this.UNCuser[0].userName}`);
        this.licencesInfo = response.data.skuName;
      } catch (err) {
        if(err?.response?.status === 404) {
          this.licencesInfo = 'Google Workspace for Education Fundamentals.';
        } else {
          this.licencesInfo = null;
        }
      }
    },
  }
};
</script>
<style lang="scss" scoped>

    .my-listview-item {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }
    #tooltip {
     /* ... */
        display: none;
}

#tooltip[data-show] {
  display: block;
}

 #tooltip {
        background: #333;
        color: white;
        font-weight: bold;
        padding: 4px 8px;
        font-size: 13px;
        border-radius: 4px;
      }
</style>